import Cookies from 'js-cookie';

window.addEventListener('turbolinks:load', function() {
    if (Cookies.get('hideContactLayer')) {
        $('#contact_layer').hide();
    } else {
        $('#contact_layer').show();
    }
    $('#contact_layer .contact_layer--close').on('click', function() {
        $('#contact_layer').fadeOut(300);
        Cookies.set('hideContactLayer', 'yes', {
            expires: 1
        });
        return false;
    });

    $('#contact_layer form.contact_layer--form').on('submit', function(e) {
        e.preventDefault();
        var params = $(this).serialize();

        $.post('/api/ask-for-contact', params, function(response) {
            if (response.status == 'success') {
                $('#contact_layer form.contact_layer--form').hide();
                $('#contact_layer .contact_layer--success').show();
                setTimeout(function() {
                    $('#contact_layer').fadeOut(300);
                    // Cookies.set('hideContactLayer', 'yes', {
                    //     expires: 1
                    // });
                }, 5000)
            } else {
                $('#contact_layer form.contact_layer--form .form-group .invalid-feedback').text(response.errors.phone);
            }
        })
    })
});