// menu
window.addEventListener('turbolinks:load', function() {
    const menuButton = document.querySelector('.mobile_bars');
    menuButton.addEventListener('click', function(e) {
        e.preventDefault();
        menuButton.classList.toggle('open');
        const navMenu = document.querySelector('.page-nav');
        navMenu.classList.toggle('open');
    });
    const submenuTriggers = document.querySelectorAll('.page_header .page-nav li.has-submenu > a');
    submenuTriggers.forEach(function(el) {
        el.addEventListener('click', function(e) {
            if (window.outerWidth < 768) {
                e.preventDefault();
                $(el).siblings('ul').slideToggle(300);
                // el.parentNode.classList.toggle('open');
            }
        })
    })
});
