import Cookies from 'js-cookie';

window.addEventListener('turbolinks:load', function() {
    if (Cookies.get('hideCookiePolicy')) {
        $('.cookie_info').hide();
    } else {
        $('.cookie_info').show();
    }
    $('.cookie_info .cookie_info--close').on('click', function() {
        $('.cookie_info').fadeOut(300);
        Cookies.set('hideCookiePolicy', 'yes', {
            expires: 365
        });
        return false;
    });
});