// quiz form
window.addEventListener('turbolinks:load', function() {
  var orderForm = $('form#shopOrderForm');

  if (orderForm.length > 0) {
    var isCompanyInput = orderForm.find('#shop_order_company');
    var updateIsCompany = function() {
      if (isCompanyInput.is(':checked')) {
        orderForm.find('.form-group.shop_order_invoice_details').show();
      } else {
        orderForm.find('.form-group.shop_order_invoice_details').hide();
      }
    }
    isCompanyInput.on('change', updateIsCompany);
    updateIsCompany();
  }

  $('#shop_order_payment_type_paypal').parent().addClass('radio_paypal_method');
  $('#shop_order_payment_type_payu').parent().addClass('radio_payu_method');
});