// quiz form
window.addEventListener('turbolinks:load', function() {
    var holidayCourseForm = $('form#new_holiday_course_application');

    if (holidayCourseForm.length > 0) {
        var accommodationInput = holidayCourseForm.find('#holiday_course_application_accommodation');
        var updateAccommodation = function() {
            if (accommodationInput.val() == 'with_the_family') {
                holidayCourseForm.find('.form-group.holiday_course_application_meals').show();
            } else {
                holidayCourseForm.find('.form-group.holiday_course_application_meals').hide();
            }
        }
        accommodationInput.on('change', updateAccommodation);
        updateAccommodation();
    }
});
