// quiz form
window.addEventListener('turbolinks:load', function() {
  var orderForm = $('form#courseOrderForm');

  if (orderForm.length > 0) {
    var isCompanyInput = orderForm.find('#order_company');
    var updateIsCompany = function() {
      if (isCompanyInput.is(':checked')) {
        orderForm.find('.form-group.order_invoice_details').show();
      } else {
        orderForm.find('.form-group.order_invoice_details').hide();
      }
    }
    isCompanyInput.on('change', updateIsCompany);
    updateIsCompany();

    const courseSelect = orderForm.find('select#order_course_id');
    const levelsSelect = orderForm.find('select#order_level_id');

    var updateCourseDates = function(dates) {
      var checkboxes = document.querySelectorAll('#order_dates input[type=checkbox]:checked');
      var selectedDates = Array.prototype.map.call(checkboxes, input => input.name);
      var datesContainer = document.getElementById('order_dates');

      datesContainer.innerHTML = '';
      // $('#order_dates').html('');

      for (const [date, hours] of Object.entries(dates)) {
        var box = document.createElement('div');
        box.classList.add('order_page--day');
        var heading = document.createElement('h4');
        heading.innerHTML = date
        box.appendChild(heading);
        var hoursBox = document.createElement('div')
        hoursBox.classList.add('order_page--dates');
        for (const hour of hours) {
          var formCheck = document.createElement('div');
          formCheck.classList.add('form-check');
          var input = document.createElement('input');
          input.type = 'checkbox';
          input.id = `${date} ${hour}`;
          input.name = `order[course_dates][${date}][${hour}]`;
          formCheck.appendChild(input)
          var label = document.createElement('label')
          label.innerHTML = hour
          label.htmlFor = `${date} ${hour}`;
          formCheck.appendChild(label);
          hoursBox.appendChild(formCheck);
          input.checked = selectedDates.indexOf(input.name) >= 0;
        }
        box.appendChild(hoursBox);
        datesContainer.appendChild(box);
      }
    }

    var updateCourse = function() {
      const selectedCourse = courseSelect.val();
      const courseReadMore = orderForm.find('#courseReadMore')

      if (selectedCourse == '') {
        levelsSelect.parents('.form-group').hide();
        courseReadMore.hide();
      } else {
        const selectedOption = courseSelect.find(`option[value=${selectedCourse}]`);
        const selectedCourseName = selectedOption.text();
        $('#course_name').text(selectedCourseName);

        $.get(`/api/courses/${selectedCourse}`, function(response) {
          var selectedLevel = levelsSelect.val();
          levelsSelect.html('');
          levelsSelect.append($('<option>').text('Wybierz poziom').val(''));

          if (Object.keys(response.levels).length > 0) {
            levelsSelect.parents('.form-group').show();
            for (const [value, label] of Object.entries(response.levels)) {
              const option = $('<option>').attr('value', value).text(label);
              if (value == selectedLevel) {
                option.prop('selected', true);
              }
              levelsSelect.append(option);
            }
          } else {
            levelsSelect.parents('.form-group').hide();
          }
          orderForm.find('#course_price').text(response.course_cost);
          orderForm.find('#total_cost').text(response.total_cost);
          updateCourseDates(response.dates);
        });

        const readMoreLink = courseReadMore.find('a');
        readMoreLink.prop('href', selectedOption.data('url'));
        courseReadMore.show();
      }
    }
    courseSelect.on('change', updateCourse);
    updateCourse();

    levelsSelect.on('change', function() {
      var selectedLevel = $(this).val();
      if (selectedLevel != '') {
        const selectedLevelName = levelsSelect.find(`option[value=${selectedLevel}]`).text();
        $('#level_name').text(selectedLevelName);
      }
    });

    $('#discount_code_trigger').on('click', function(e) {
      e.preventDefault();
      orderForm.find('.order_page--discount_code').slideDown(300);
    });

    $('.order_page--discount_code #discount_code').on('keydown', function(e) {
      if (e.keyCode == 13) {
        $('.order_page--discount_code input.btn').trigger('click');
        return false;
      }
    });

    $('.order_page--discount_code input.btn').on('click', function(e) {
      e.preventDefault();
      const discountCode = orderForm.find('#discount_code').val();
      $.post('/api/discount_codes/check', {
        code: discountCode
      }, function(response) {
        if (response.code && response.discount) {
          orderForm.find('#discount_code_text > span').text(response.code);
          orderForm.find('#discount_code_box').addClass('active');
          orderForm.find('.order_page--discount_code').slideUp(300);
        }
        updateCourse();
      }).catch(function(response) {
        if (response.status == 404) {
          alert('Błędny kod/Invalid code');
        }
      });
    });

    $('#discount_code_reset').on('click', function() {
      $.post('/api/discount_codes/reset', {}, function(response) {
        if (response.status == 'success') {
          orderForm.find('#discount_code_text > span').text('');
          orderForm.find('#discount_code_box').removeClass('active');
        }
        updateCourse();
      });
      return false;
    })

    var courseOptionsCache = [];
    orderForm.find('select#order_course_id option').each(function() {
      courseOptionsCache.push($(this).clone());
    });

    $('#courseOrderForm input[name=category_filter]').on('change', function() {
      const categoryFilter = $(this).val();
      const courseSelect = orderForm.find('select#order_course_id');

      var selectedCourseOption = courseSelect.find('option:selected')
      const newOptions = [];

      courseOptionsCache.forEach(function(option) {
        if (option.data('categoryId') == categoryFilter || option.data('categoryId') == undefined) {
          newOptions.push(option);
        }
      });
      courseSelect.html(newOptions);
      if (selectedCourseOption.data('categoryId') == categoryFilter) {
        courseSelect.val(selectedCourseOption.val());
      } else {
        courseSelect.val('');
      }
    });
  }

  if ($('#paypal-button-container').length > 0) {
    var initPaypal = function() {
      paypal.Buttons({
        createOrder: function(data, actions) {
          var orderParams = {
            id: $('#paypal-button-container').data('id'),
            token: $('#paypal-button-container').data('token'),
            type: $('#paypal-button-container').data('type')
          }
          $('#paypal-button-container').slideUp(100);
          return $.post(
            '/payments/paypal/create',
            orderParams,
            function() {},
            'json'
          ).then(function(data) {
            if (data.status == 'CREATED') {
              return data.id;
            } else {

            }
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
            if (details.status == 'COMPLETED') {
              $.post('/payments/paypal/' + details.id + '/confirm', {}, function(response) {
                if (response.status == 'OK' && response.order_url) {
                  $(location).attr('href', response.order_url);
                }
              }, 'json');
            }
          });
        }
      }).render('#paypal-button-container');
    }
    initPaypal();
  }

  $('#order_payment_type_paypal').parent().addClass('radio_paypal_method');
  $('#order_payment_type_payu').parent().addClass('radio_payu_method');

  if ($('input[type=radio][name=order\\[payment_type\\]]').length === 1) {
    $('input[type=radio][name=order\\[payment_type\\]]').first().click();
  }
});