const images = require.context('../images', true)

function initMap() {
    const mapNode = document.getElementById("contact_map");
  
  if (mapNode == undefined) {
    return;
  }

  const companyLatLng = { lat: 50.0705467, lng: 19.9314641 };
  const map = new google.maps.Map(mapNode, {
        zoom: 17,
        center: companyLatLng
  });


    function geoSuccess(position) {
        var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;

    var rendererOptions = {
      map: map,
      suppressMarkers: true,
      polylineOptions: { strokeColor: "#8b0013" }
    }
    const directionsService = new google.maps.DirectionsService;
    const directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);

    var homeLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
    var homeMarker = new google.maps.Marker({
        position: homeLatLng,
        map,
        icon: images('./pin.png'),
        title: 'Moja lokalizacja'
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(companyLatLng);
    bounds.extend(homeLatLng);
    directionsDisplay.setMap(map);
    map.fitBounds(bounds);

    directionsService.route({
          origin: homeLatLng,
          destination: companyLatLng,
          travelMode: 'WALKING'
      }, function(response, status) {
          if (status === 'OK') {
              directionsDisplay.setDirections(response);
          } else {
              window.alert('Directions request failed due to ' + status);
          }
      });
    }

    function geoError() {

    }

    function getLocation() {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
          // alert("Geolocation is supported by this browser.");
      } else {
          alert("Geolocation is not supported by this browser.");
      }
    }

  
  const companyMarker = new google.maps.Marker({
      position: companyLatLng,
      map,
      icon: images('./pin.png'),    
      title: 'El Alabanico'
  });
  getLocation();
}
window.addEventListener('turbolinks:load', initMap);
