// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Rails from '@rails/ujs';
import { WOW } from 'wowjs';
import { CountUp } from 'countup.js';
import Plyr from 'plyr';

import '../styles/application.scss';

import "wowjs/css/libs/animate.css"
import 'plyr/dist/plyr.css';

require('../page/contact-map')
require('../page/slick-sliders')
require('../page/quiz-form')
require('../page/page-menu')
require('../page/order-form')
require('../page/shop-order-form')
require('../page/shop')
require('../page/cookies-layer')
require('../page/contact-layer')
require('../page/holiday-courses')

const images = require.context('../images', true)

window.jQuery = $;

// wow
window.addEventListener('turbolinks:load', function() {
  Rails.refreshCSRFTokens();
	new WOW().init();
	const counters = document.querySelectorAll('[data-countup-value]');
	counters.forEach(node => {
		const countUp = new CountUp(node, node.dataset.countupValue, { separator: '' });
		countUp.start();
	});
});

window.addEventListener('turbolinks:load', function() {
    const players = Array.from(document.querySelectorAll('.plyr_player')).map(p => new Plyr(p, {
        controls: ['play-large']
    }));

    $('select.product_variant').on('change', function() {
        var option = $(this).find('option:selected');
        if (option) {
            var optionData = option.data();
            $(location).attr('href', optionData.url);
        }
    })
})


window.addEventListener('turbolinks:load', function() {
    $(window).on('scroll', function() {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > 0) {
            $('body').addClass('scrolled');
        } else {
            $('body').removeClass('scrolled');
        }
    });
    $('#scroll_up').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        $("html, body").animate({
            scrollTop: 0
        }, 1000);
    });
});

window.addEventListener('turbolinks:load', function() {
    var scrollToTeachers = function() {
        if (location.hash == '#lektorzy' && $(location.hash).length == 1) {
            console.log($(location.hash).offset().top)
            window.scrollTo(0, $(location.hash).offset().top); 
        }
    }
    setTimeout(scrollToTeachers, 100);
    setTimeout(scrollToTeachers, 500);
});

window.addEventListener('turbolinks:load', function() {
    $('.levels_page--list .levels_page--box_heading').on('click', function() {
      const boxContent = $(this).siblings('.levels_page--box_content')
       $('.levels_page--list .levels_page--box_content').not(boxContent).slideUp(300);
      boxContent.slideDown(300);
      return false;
    })
});