require("slick-carousel")
require("lightgallery")
require("lg-thumbnail")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import "lightgallery/dist/css/lightgallery.min.css"

// slick
window.addEventListener('turbolinks:load', function() {
  console.log('a');
    $('.course_gallery').slick({
        variableWidth: true,
    });

    $('.home_page--slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 5000
    });

    // $('.about_page--gallery_list').lightGallery({
    //     selector: '.about_page--gallery_item',
    //     thumbnail: true
    // });

    // $('.about_page--gallery_list').slick({
    //     variableWidth: true,
    //     arrows: true
    // });

    setTimeout(function() {
      $('.holiday_courses--gallery_list').slick({
          variableWidth: true,
          infinite: true,
          // slidesToShow: 1,
          centerPadding: '10px',
          centerMode: true,
          dots: false,
          arrows: true,
      });
    }, 250)

    $('.product_page--gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        fade: true,
        asNavFor: '.product_page--thumbs'
    });
    $('.product_page--thumbs').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        centerMode: true,
        arrows: true,
        asNavFor: '.product_page--gallery',
    focusOnSelect: true
    });

    $('.pricing_table_mobile').slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                }
            }
        ]
    });
});