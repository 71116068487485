// quiz form
window.addEventListener('turbolinks:load', function() {
    $(document).on('submit', '#quizForm', function(e) {
        e.preventDefault();
        var data = $(this).serializeArray();

        $.post($(this).prop('action'), data, function(response) {
            if (response.status == 'success') {
                $(location).attr('href', response.url)

            } else if (response.status == 'error') {
                var ajaxForm = $(response.form);

                $('#quizForm').replaceWith(ajaxForm.find('#quizForm'));
            }
        }, 'json')
    })
});
