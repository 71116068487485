// shop page
window.addEventListener('turbolinks:load', function() {
    $('.shop_page--filters select').on('change', function() {
        $(this).parents('form.shop_page--filters').trigger('submit');
    });

    $('.product_page .btn-plus').on('click', function(e) {
        e.preventDefault();
        var quantityBox = $('.product_page .product_page--quantity_box input[name=quantity]');
        var quantity = parseInt(quantityBox.val(), 10) + 1;
        quantityBox.val(quantity);
    });
    $('.product_page .btn-minus').on('click', function(e) {
        e.preventDefault();
        var quantityBox = $('.product_page .product_page--quantity_box input[name=quantity]');
        var quantity = parseInt(quantityBox.val(), 10) - 1;
        if (quantity < 1) {
            quantity = 1;
        }
        quantityBox.val(quantity);
    });

    $('.basket_page .quantity_box a.btn').on('click', function() {
        var url = $(this).prop('href');
        var link = $(this);
        var table = $(this).parents('.basket_table');

        $.post(url, function(response) {
            var container = link.parents('.basket_table--row')
            if (response.quantity > 0) {
                container.find('.basket_item--item_price').text(response.item_price)
                container.find('.basket_item--price').text(response.price)
                container.find('.quantity_box--number').text(response.quantity)
                $('.basket_page .basket_prices--products_value').text(response.total_price);
                $('.basket_page .basket_prices--total_value strong').text(response.total_price);
            } else {
                container.remove();
            }
        }, 'json');
        return false;
    });

});
